<template>
  <base-layout>
    <!--header-bar></header-bar-->
    <ion-content>
      <div>
        <ion-list class="wcpt-session-list">

          <ion-item style="width:100%; margin-top:10px" class="padding" v-if="sessions.length == 0">No session running now or in the next 15 minutes</ion-item>
          <div v-for="session in sessions" :key="session.id">
            <!-- add color --- :style="{'background': 'linear-gradient(65deg,' +  session.color +', transparent'}"-->
            <a @click="showDisclaimer(session)" class="wcpt-session-item "
               v-if="!session.isHeader && session.id !='da7ad0c0-3ed1-4500-1302-010000000189'"
               :style="{'border-color':  session.color + ' !important'}">

              <img class="picture" src="@/assets/icon/avatar.png"
                   v-if="!session.chair || !session.chair[0] || !session.chair[0].vitae_picture">
              <img class="picture"
                   :src="getChairPicture(session)"
                   v-if="session.chair && session.chair[0] && session.chair[0].vitae_picture">
              <div class="head">
                <div class="type">{{ session.session_type_text }}</div>
                <div class="time" v-if="!isOnDemand">{{ moment(session.start_time).format('dddd HH:mm') }} -
                  {{ moment(session.end_time).format('HH:mm') }} (CEST)
                </div>
              </div>
              <div class="content">
                <div class="title">{{ session.title }}</div>
                <div class="chair" v-if="session.chair.length > 0"><strong>Chair:</strong> <span
                    v-for="chair in session.chair" :key="chair">{{ chair.display_name }}</span></div>
                <div class="speaker" v-if="session.speakers.length > 0">
                  <strong>Speakers:</strong> <span v-for="speaker in session.speakers"
                                                   :key="speaker">{{
                    speaker.first_name
                  }} {{ speaker.last_name }} </span>
                </div>
              </div>
              <!--add color --- :style="{'background-color': session.color}"-->
              <div class="shape" :style="{'background-color': session.color}">
                <!--add color--- :style="{'color': session.font_color}"-->
                <div class="room" v-if="session.location" :style="{'color': session.font_color}"><p class="channel"
                >
                  {{ session.location.name }}</p></div>
              </div>
            </a>
            <div v-if="session.isHeader && !isOnDemand" class="item item-divider"
                 style="width:100vw; padding-left:20px">
              <h2> {{ moment(session.start_time).format('HH:mm') }}</h2>
            </div>
          </div>
        </ion-list>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonList, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import {gridOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      location: null,
      isOnDemand: false
    }
  },

  setup() {
    return {
      gridOutline,
    }
  },
  components: {
    IonContent,
    IonList,
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapGetters('auth', ['user']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionListLive']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions() {

      let sessions = (await this.getSessionListLive()).sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      let livesessions = sessions.filter(session => !!this.checkTime(session)
      )
      if (livesessions) {
        livesessions.sort((a, b) => {
          if (a.location && b.location) {
            return a.location.order - b.location.order
          } else {
            return 0
          }
        })
      }
      console.log('livesessions', livesessions)

      this.sessions = this.groupList(livesessions, 'start_time');
      console.log('sessions', this.sessions)
      this.days = {};
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + "+02:00");
      sessionEnd = new Date(session.end_time + "+02:00");

      liveSessionStart = moment(sessionStart).subtract(15, "minutes");
      liveSessionEnd = moment(sessionEnd).add(0, "minutes");
      let liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      console.log('is live', liveSessionStart, liveSessionEnd, dateNow, liveSessionActive)
      return liveSessionActive
    },


    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHeader: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, MMMM D')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToGrid() {
      this.$router.push('/app/gridList');
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {

    this.isOnDemand = this.$route.params.room == 'da7ad0c0-3ed1-4500-1302-080000000018';
    this.fetchSessions();
    this.activeDay = this.$route.params.day;
    this.moment = moment;
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
    },
  },
});
</script>
<style lang="scss">

</style>